import React from 'react';

import { Navigation } from '../Navigation';
import { Footer } from '../Footer';


import '../../styles/main.css'

export function Layout({ children, location }) {
  return (
    <div className="flex flex-col min-h-screen">
      <Navigation location={location} />
      <div>{children}</div>
      <Footer />
    </div>
  )
}
