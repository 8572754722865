import React from 'react';
import PropTypes from 'prop-types'
import { useLocation } from '@reach/router'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl
      }
    }
  }
`;

export function SEO({ title }) {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultDescription,
    defaultTitle,
    siteUrl,
  } = site.siteMetadata;

  const seo = {
    title: `${defaultTitle}${title ? ' | ' + title : ''}`,
    url: `${siteUrl}${pathname}`
  }

  return (
    <Helmet title={seo.title}>
      <meta name="description" content={defaultDescription} />
      <meta
        name="keywords"
        content="gynaecology,private doctor,gynaecologist female,PCOS,HRT,hysterectomy,endometriosis,private consultant,female private doctor,menopause,nottingham doctor,pelvic pain, Dr Zeina Haoula"
      />

      {/* facebook meta tags */}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {defaultDescription && <meta property="og:description" content={defaultDescription} />}

      {/* twitter meta tags */}
      <meta name="twitter:card" content="summary" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {defaultDescription && <meta name="twitter:description" content={defaultDescription} />}
    </Helmet>
  )
}

SEO.propTypes = {
  title: PropTypes.string
}

SEO.defaultProps = {
  title: null
}
