import React from 'react';

import { Link } from 'gatsby';

export function Footer () {
  return (
    <div className="bg-main text-white py-3 mt-auto px-5 md:px-0">
      <div className="container mx-auto flex justify-between items-center">
        © {new Date().getFullYear()} — Dr. Z. Haoula
        <Link to='/privacy'>Cookies usage</Link>
      </div>
    </div>
  )
}
